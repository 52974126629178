import { styled, useTheme } from '@mui/material/styles';
import { Outlet } from 'react-router';

import { useDispatch, useSelector } from 'src/redux/store';
import { setIsOpenSidebar } from 'src/redux/slices/ui';

import DashboardSidebar from './DashboardSidebar';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 68;
const APP_BAR_DESKTOP = 104;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: `calc(100vh - ${APP_BAR_MOBILE}px)`,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minHeight: `calc(100vh - ${APP_BAR_DESKTOP}px)`
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const theme = useTheme();
  const { collapseClick } = useCollapseDrawer();
  const dispatch = useDispatch();
  const isOpenSidebar = useSelector((state) => state.ui.isOpenSidebar);

  return (
    <RootStyle>
      <DashboardSidebar isOpenSidebar={isOpenSidebar} onCloseSidebar={() => dispatch(setIsOpenSidebar(false))} />
      <MainStyle
        sx={{
          transition: theme.transitions.create('margin', {
            duration: theme.transitions.duration.complex
          }),
          ...(collapseClick && {
            ml: '102px'
          })
        }}
      >
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
