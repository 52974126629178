import { Icon } from '@iconify/react';
import { useState } from 'react';
import searchFill from '@iconify/icons-eva/search-fill';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useTranslation } from 'react-i18next';

import { styled, alpha } from '@mui/material/styles';
import { Box, Input, Slide, Button, InputAdornment, ClickAwayListener } from '@mui/material';

import { setActiveFilters } from 'src/redux/slices/reservations';

import { useDispatch } from 'src/redux/store';
import { MIconButton } from '../../components/@material-extend';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';

export const validationSchema = Yup.object().shape({
  q: Yup.string().required()
});

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled('div')(({ theme }) => ({
  top: 0,
  left: -16,
  right: -16,
  zIndex: 99,
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  backgroundColor: `${alpha(theme.palette.background.default, 0.72)}`,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
    left: -40,
    right: -40
  }
}));

// ----------------------------------------------------------------------

export default function Searchbar() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      q: ''
    },
    validationSchema,
    onSubmit: async (values) => {
      dispatch(setActiveFilters(values));
      navigate(PATH_DASHBOARD.reservations.list);
    }
  });

  const { values, handleChange, handleSubmit } = formik;

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!isOpen && (
          <MIconButton onClick={handleOpen}>
            <Icon icon={searchFill} width={20} height={20} />
          </MIconButton>
        )}

        <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
          <SearchbarStyle>
            <form noValidate autoComplete="off" onSubmit={handleSubmit} style={{ width: '100%' }}>
              <Box width="100%" display="flex">
                <Input
                  autoFocus
                  fullWidth
                  disableUnderline
                  placeholder={t('Znajdź rezerwację...')}
                  name="q"
                  value={values.q}
                  onChange={handleChange}
                  startAdornment={
                    <InputAdornment position="start">
                      <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </InputAdornment>
                  }
                  sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
                />
                <Button type="submit" variant="contained">
                  {t('Szukaj')}
                </Button>
              </Box>
            </form>
          </SearchbarStyle>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}
