import { useLocation, Outlet } from 'react-router';
// material
import MainNavbar from './MainNavbar';
import MainFooter from './MainFooter';

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation();
  const isHome = pathname === '/';

  return (
    <>
      <MainNavbar />

      <Outlet />

      {!isHome ? <MainFooter /> : null}
    </>
  );
}
