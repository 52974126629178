import { ConfirmProvider } from 'material-ui-confirm';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// hooks
import useAuth from './hooks/useAuth';
// components
import ScrollToTop from './components/ScrollToTop';
import GoogleAnalytics from './components/GoogleAnalytics';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import ThemeLocalization from './components/ThemeLocalization';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import LoadingScreen, { ProgressBarStyle } from './components/LoadingScreen';
import { useEffect } from 'react';

// ----------------------------------------------------------------------

export default function App() {
  const { isInitialized } = useAuth();

  useEffect(() => {
    /* Disable number input value withe mouse scroll */
    document.addEventListener('wheel', function (event) {
      if (document.activeElement.type === 'number') {
        document.activeElement.blur();
      }
    });
  }, []);

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <ThemeLocalization>
          <NotistackProvider>
            <GlobalStyles />
            <ProgressBarStyle />
            <BaseOptionChartStyle />
            <ScrollToTop />
            <GoogleAnalytics />
            <ConfirmProvider>{isInitialized ? <Router /> : <LoadingScreen />}</ConfirmProvider>
          </NotistackProvider>
        </ThemeLocalization>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
