import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> }
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        { path: 'analytics', element: <GeneralAnalytics /> },
        { path: 'banking', element: <GeneralBanking /> },
        { path: 'booking', element: <GeneralBooking /> },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> }
          ]
        },
        {
          path: 'yachts',
          children: [
            { element: <Navigate to="/dashboard/yachts/list" replace />, index: true },
            { path: 'list', element: <YachtsList /> },
            { path: 'create', element: <YachtsCreate /> },
            { path: ':id', element: <YachtsDetails /> }
          ]
        },
        {
          path: 'special-offers',
          children: [{ path: 'matrix', element: <SpecialOffers />, index: true }]
        },
        {
          path: 'reservations',
          children: [
            { element: <Navigate to="/dashboard/reservations/list" replace />, index: true },
            { path: 'list', element: <ReservationsList /> },
            { path: 'matrix', element: <ReservationsMatrix /> },
            { path: 'create', element: <ReservationCreate /> },
            { path: ':id', element: <ReservationDetails /> }
          ]
        },
        {
          path: 'settings',
          children: [
            { element: <Navigate to="/dashboard/yachts/list" replace />, index: true },
            { path: 'templates', element: <Templates /> }
          ]
        },
        {
          path: 'zegluj',
          children: [{ path: 'payouts', element: <Payouts /> }]
        },
        {
          path: 'reports',
          children: [
            { path: 'bosuns', element: <BosunsReport /> },
            { path: 'cleaning-service', element: <CleaningServiceReport /> }
          ]
        }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [{ element: <Navigate to="/dashboard" />, index: true }]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/authentication/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
// const Calendar = Loadable(lazy(() => import("../pages/dashboard/Calendar")));
// const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));
// Main
// const LandingPage = Loadable(lazy(() => import("../pages/LandingPage")));
// const About = Loadable(lazy(() => import("../pages/About")));
// const Contact = Loadable(lazy(() => import("../pages/Contact")));
// const Faqs = Loadable(lazy(() => import("../pages/Faqs")));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const YachtsList = Loadable(lazy(() => import('../pages/dashboard/YachtsList')));
const ReservationsList = Loadable(lazy(() => import('../pages/dashboard/ReservationsList')));
const YachtsCreate = Loadable(lazy(() => import('../pages/dashboard/YachtsCreate')));
const YachtsDetails = Loadable(lazy(() => import('../pages/dashboard/YachtsDetails')));
const ReservationDetails = Loadable(lazy(() => import('../pages/dashboard/ReservationDetails')));
const ReservationCreate = Loadable(lazy(() => import('../pages/dashboard/ReservationCreate')));
const ReservationsMatrix = Loadable(lazy(() => import('../pages/dashboard/ReservationsMatrix')));
const SpecialOffers = Loadable(lazy(() => import('../pages/dashboard/SpecialOffers')));
const Templates = Loadable(lazy(() => import('../pages/dashboard/Templates')));
const Payouts = Loadable(lazy(() => import('../pages/dashboard/Payouts')));
const BosunsReport = Loadable(lazy(() => import('../pages/dashboard/BosunsReport')));
const CleaningServiceReport = Loadable(lazy(() => import('../pages/dashboard/CleaningServiceReport')));
