import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';

import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

// material
import { alpha } from '@mui/material/styles';
import { Autocomplete, Box, Button, TextField, CircularProgress, Divider, Typography } from '@mui/material';

import { useDispatch } from 'src/redux/store';
import { resetStores } from 'src/redux/slices/root';

// routes
// hooks
import useAuth from '../../hooks/useAuth';

// components
import { MIconButton } from '../../components/@material-extend';
import MenuPopover from '../../components/MenuPopover';

import { PATH_DASHBOARD } from 'src/routes/paths';

export default function SwitchAccountPopover() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { getPartnersForSuperUser, isPartnersLoading, partners, setActivePartner, activePartner, resetActivePartner } =
    useAuth();
  const navigate = useNavigate();

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    if (!partners && !isPartnersLoading) {
      getPartnersForSuperUser('');
    }
    setOpen(true);
  }, [partners, isPartnersLoading, getPartnersForSuperUser]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const selectPartnerHandler = useCallback(
    (ev, option) => {
      setActivePartner(option);
      dispatch(resetStores());

      navigate(PATH_DASHBOARD.root);
    },
    [navigate, setActivePartner, dispatch]
  );

  const resetSelectedPartnerHandler = useCallback(() => {
    resetActivePartner();
    dispatch(resetStores());
    navigate(PATH_DASHBOARD.root);
  }, [navigate, resetActivePartner, dispatch]);

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: '0 15px',
          height: 44,
          '@media(max-width: 800px)': { maxWidth: 160 },
          backgroundColor: `#ff4842`,
          borderRadius: '50px',
          color: 'white',
          '&:hover': { bgcolor: alpha(`#ff4842`, 0.8) },
          ...open
        }}
      >
        <SwitchAccountIcon />
        <Typography variant="subtitle1" noWrap ml={1}>
          {activePartner ? activePartner.name : 'Wszyscy'}
        </Typography>
      </MIconButton>
      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 'fit-content' }}>
        {activePartner ? (
          <>
            <Box sx={{ p: 2, pt: 1.5 }}>
              <Typography variant="subtitle1" noWrap>
                {activePartner.name}
              </Typography>
            </Box>
            <Divider sx={{ my: 1 }} />
          </>
        ) : null}
        <Box sx={{ my: 1.5, px: 2.5, position: 'relative' }}>
          <Autocomplete
            disablePortal
            freeSolo
            loading={isPartnersLoading}
            options={partners || []}
            sx={{ width: 300 }}
            getOptionLabel={(option) => option.name}
            onChange={selectPartnerHandler}
            renderInput={(params) => (
              <TextField
                {...params}
                autoFocus
                label={t('Znajdź partnera...')}
                variant="outlined"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isPartnersLoading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
        </Box>
        {activePartner ? (
          <>
            <Divider sx={{ my: 1 }} />
            <Box sx={{ p: 2, pt: 1.5 }}>
              <Button fullWidth color="inherit" variant="outlined" onClick={resetSelectedPartnerHandler}>
                {t('Pokaż wszystkich')}
              </Button>
            </Box>
          </>
        ) : null}
      </MenuPopover>
    </>
  );
}
